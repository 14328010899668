import React from "react"
import { Router } from "@reach/router"
import AccountView from "./views/AccountView/AccountView"
import { ROUTES } from "../../navigation/routes"
import AccountSettingsView from "./views/AccountSettingsView/AccountSettingsView"
import AccountSubscriptionsView from "./views/AccountSubscriptionsView/AccountSubscriptionsView"
import AccountShortlistView from "./views/AccountShortlistView/AccountShortlistView"
import AccountSettingsPasswordView from "./views/AccountSettingsPasswordView/AccountSettingsPasswordView"
import AccountSettingsUsernameView from "./views/AccountSettingsUsernameView/AccountSettingsUsernameView"

const AccountScreen: React.FC<{
  applyToCoachUrl: string
}> = ({ applyToCoachUrl }) => (
  <Router basepath={ROUTES.account.path}>
    <AccountView
      path={ROUTES.account.subPath}
      applyToCoachUrl={applyToCoachUrl}
    />
    <AccountSettingsView path={ROUTES.accountSettings.subPath} />
    <AccountSettingsPasswordView
      path={ROUTES.accountSettingsPassword.subPath}
    />
    <AccountSettingsUsernameView
      path={ROUTES.accountSettingsUsername.subPath}
    />
    <AccountSubscriptionsView path={ROUTES.accountSubscriptions.subPath} />
    <AccountShortlistView path={ROUTES.accountShortlist.subPath} />
  </Router>
)

export default AccountScreen
