import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import styled from "styled-components"
import { Formik } from "formik"
import firebase from "firebase/app"
import {
  BackIcon,
  StyledBackButton,
  StyledContainer,
  StyledHeader,
} from "../AccountSettingsView/AccountSettingsView"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { ROUTES } from "../../../../navigation/routes"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import ActionButton, {
  ActionButtonTheme,
  ButtonLoadingIndicator,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import ValidatedInput from "../../../../ui/inputs/ValidatedInput/ValidatedInput"
import { useSettingsPasswordFormValidation } from "./validation"
import theme from "../../../../ui/theme"
import {
  getFirebaseUserEmail,
  isFirebaseUserPasswordSignUp,
  useFirebaseUser,
} from "../../../../auth/components/AuthHandler/AuthHandler"
import { handleFirebaseErrors } from "../../../ActionScreen/views/ResetPasswordView/ResetPasswordView"
import { StyledFirebaseError } from "../../../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/components/FirebaseErrorMessage/FirebaseErrorMessage"

export const StyledInputWrapper = styled.div`
  margin-top: 20px;

  input {
    color: inherit;

    &:not(:focus) {
      border-color: #d4d4d4;
    }

    &:focus + label {
      color: unset;
    }
  }

  label {
    color: ${theme.colors.lightGrey};
  }
`

export const StyledSubmitWrapper = styled.div`
  margin-top: 72px;
`

const AccountSettingsPasswordView: React.FC<RouteComponentProps> = () => {
  const user = useFirebaseUser()
  const validationSchema = useSettingsPasswordFormValidation()
  const [passwordUpdated, setPasswordUpdated] = useState(false)

  const userDidNotSignUpViaEmail = user && !isFirebaseUserPasswordSignUp(user)

  const onSubmit = (
    {
      newPassword,
      password,
    }: {
      newPassword: string
      password: string
    },
    {
      setErrors,
      setStatus,
      setSubmitting,
      resetForm,
    }: {
      setErrors: (arg0: any) => void
      setStatus: (arg0: any) => void
      setSubmitting: (arg0: boolean) => void
      resetForm: (arg0?: any) => void
    }
  ) => {
    setPasswordUpdated(false)
    const userEmail = user ? getFirebaseUserEmail(user) : null

    if (!userEmail) {
      const error = `No email found for the user, which is required to update their password.`
      console.error(error)
      Sentry.captureMessage(error)
      return Promise.reject()
    }
    return firebase
      .auth()
      .signInWithEmailAndPassword(userEmail, password)
      .then(() => {
        const { currentUser } = firebase.auth()
        if (currentUser) {
          return currentUser
            .updatePassword(newPassword)
            .then(() => {
              setPasswordUpdated(true)
              resetForm()
            })
            .catch(error => {
              console.error(error)
              handleFirebaseErrors(error, setErrors, setStatus, {
                password: "newPassword",
              })
            })
        }
        return Promise.reject()
      })
      .catch(error => {
        console.error(error)
        handleFirebaseErrors(error, setErrors, setStatus)
      })
  }

  useEffect(() => {
    if (userDidNotSignUpViaEmail) {
      navigate(ROUTES.accountSettings.path, {
        replace: true,
      })
    }
  }, [userDidNotSignUpViaEmail])

  return (
    <StyledContainer>
      <MaxWidthContent>
        <StyledHeader>
          <StyledBackButton as={Link} to={ROUTES.accountSettings.path}>
            <BackIcon />
          </StyledBackButton>
          <MediumPlusHeading>Password</MediumPlusHeading>
        </StyledHeader>
        <Formik
          initialValues={{
            password: "",
            newPassword: "",
            passwordConfirm: "",
          }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          isInitialValid={false}
        >
          {({
            values,
            errors,
            status,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            isValid,
          }) => {
            return (
              <form noValidate onSubmit={handleSubmit}>
                <StyledInputWrapper>
                  <ValidatedInput
                    name="password"
                    label="Current password"
                    type="password"
                    valid={touched.password && !errors.password}
                    errorMessage={errors.password || ""}
                    invalid={touched.password && !!errors.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                  />
                </StyledInputWrapper>
                <StyledInputWrapper>
                  <ValidatedInput
                    name="newPassword"
                    label="New password"
                    type="password"
                    valid={touched.newPassword && !errors.newPassword}
                    errorMessage={errors.newPassword || ""}
                    invalid={touched.newPassword && !!errors.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.newPassword}
                  />
                </StyledInputWrapper>
                <StyledInputWrapper>
                  <ValidatedInput
                    name="passwordConfirm"
                    label="New password again"
                    type="password"
                    valid={touched.passwordConfirm && !errors.passwordConfirm}
                    errorMessage={errors.passwordConfirm || ""}
                    invalid={
                      touched.passwordConfirm && !!errors.passwordConfirm
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.passwordConfirm}
                  />
                </StyledInputWrapper>
                <StyledSubmitWrapper>
                  <ActionButton
                    theme={ActionButtonTheme.SECONDARY}
                    disabled={!isValid}
                    type="submit"
                    loading={isSubmitting}
                  >
                    Save
                    {isSubmitting && <ButtonLoadingIndicator />}
                  </ActionButton>
                </StyledSubmitWrapper>
                {status && status.firebaseError && (
                  <StyledFirebaseError>
                    {status.firebaseError}
                  </StyledFirebaseError>
                )}
                {passwordUpdated && <div>Your password has been updated.</div>}
              </form>
            )
          }}
        </Formik>
      </MaxWidthContent>
    </StyledContainer>
  )
}

export default AccountSettingsPasswordView
