import React from "react"
import styled from "styled-components"
import PopupPrompt from "../SignUpPrompt/components/PopupPrompt/PopupPrompt"
import LargeHeading from "../../ui/typography/LargeHeading/LargeHeading"
import ActionButton, {
  ButtonLoadingIndicator,
} from "../../ui/buttons/ActionButton/ActionButton"

const StyledDescription = styled.p`
  margin-top: 8px;
  margin-bottom: 23.5px;
`

const ResubscribeSubscriptionPrompt: React.FC<{
  onClose: () => void
  busy: boolean
  onRenew: () => void
}> = ({ onClose, busy, onRenew }) => {
  return (
    <PopupPrompt onClose={onClose} disableClose={busy} opaque={false}>
      <>
        <LargeHeading>Renew Subscription.</LargeHeading>
        <StyledDescription>
          Are you sure you want
          <br /> to renew your subscription?
        </StyledDescription>
        <div>
          <ActionButton onClick={onRenew} loading={busy}>
            Renew Subscription.
            {busy && <ButtonLoadingIndicator />}
          </ActionButton>
        </div>
      </>
    </PopupPrompt>
  )
}

export default ResubscribeSubscriptionPrompt
