import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Link } from "gatsby"
import styled from "styled-components"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { ROUTES } from "../../../../navigation/routes"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import {
  BackIcon,
  StyledBackButton,
  StyledContainer,
  StyledHeader,
} from "../AccountSettingsView/AccountSettingsView"
import SubscribedCoach, {
  PlaceholderSubscribedCoach,
} from "./components/SubscribedCoach/SubscribedCoach"
import theme from "../../../../ui/theme"
import { useGetUserSubscriptions } from "../../../../api/payment/hooks"
import { COACH_ENTRY_POINTS } from "../../../CoachScreen/CoachScreen"
import PageLocation from "../../../../analytics/components/PageLocation/PageLocation"

const StyledCoachesList = styled.ul`
  margin-top: -28px;
  > li {
    padding-top: 30px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${theme.colors.faintWhite};
  }
`

const AccountSubscriptionsView: React.FC<RouteComponentProps> = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [subscriptions, loading, error, refetch] = useGetUserSubscriptions()
  return (
    <PageLocation
      coachEntryLocation={COACH_ENTRY_POINTS.Subscriptions}
      pageName={ROUTES.accountSubscriptions.name}
    >
      <StyledContainer>
        <MaxWidthContent>
          <StyledHeader>
            <StyledBackButton as={Link} to={ROUTES.account.path}>
              <BackIcon />
            </StyledBackButton>
            <MediumPlusHeading>My Coaches</MediumPlusHeading>
          </StyledHeader>
          <StyledCoachesList>
            {loading && (
              <>
                <li>
                  <PlaceholderSubscribedCoach />
                </li>
                <li>
                  <PlaceholderSubscribedCoach />
                </li>
                <li>
                  <PlaceholderSubscribedCoach />
                </li>
              </>
            )}
            {!loading && subscriptions.length === 0 && (
              <li>No active subscriptions.</li>
            )}
            {subscriptions.map(subscription => {
              return subscription.status === "active" ||
                subscription.status === "trialing" ||
                (subscription.store === "app_store" &&
                  new Date() <= new Date(subscription.expires_date)) ? (
                <li key={subscription.coachId}>
                  <SubscribedCoach
                    isExpired={
                      subscription.status !== "active" &&
                      subscription.status !== "trialing"
                    }
                    isCancelled={subscription.cancelAtPeriodEnd}
                    slug={subscription.slug}
                    coachId={subscription.coachId}
                    image={subscription.avatarUrl}
                    amount={subscription.amount / 100}
                    amountRaw={subscription.amount}
                    name={subscription.name}
                    nextDate={subscription.expires_date}
                    subscriptionId={subscription.subscriptionId}
                    refetchSubscriptions={refetch}
                    canceledAt={subscription.canceledAt}
                    store={subscription.store}
                  />
                </li>
              ) : (
                <></>
              )
            })}
          </StyledCoachesList>
        </MaxWidthContent>
      </StyledContainer>
    </PageLocation>
  )
}

export default AccountSubscriptionsView
