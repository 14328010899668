import React from "react"
import { graphql } from "gatsby"
import { get } from "lodash"
import SiteLayout from "../components/SiteLayout/SiteLayout"
import SiteWrapper from "../components/SiteWrapper/SiteWrapper"
import AccountScreen from "../screens/AccountScreen/AccountScreen"
import {
  getAccountScreenApplyToCoachUrl,
  ICMSMetaSocial,
  ICMSPageMeta,
} from "../data/cms/socialMeta"
import { getEnvKey } from "../utils/env"
import PageMetaTags from "../components/SiteMeta/PageMetaTags/PageMetaTags"
import PageLocation from "../analytics/components/PageLocation/PageLocation"
import { ROUTES } from "../navigation/routes"

interface Data {
  datoCmsWebMetaSocialDev?: ICMSMetaSocial & ICMSPageMeta
  datoCmsWebMetaSocialStg?: ICMSMetaSocial & ICMSPageMeta
  datoCmsWebMetaSocial?: ICMSMetaSocial & ICMSPageMeta
  datoCmsWebMetaSocialPrd?: ICMSMetaSocial & ICMSPageMeta
}

interface Props {
  data: Data
}

const Account: React.FC<Props> = props => {
  const contentKey = getEnvKey({
    devKey: "datoCmsWebMetaSocialDev",
    stgKey: "datoCmsWebMetaSocialStg",
    uatKey: "datoCmsWebMetaSocial",
    prdKey: "datoCmsWebMetaSocialPrd",
    defaultKey: "datoCmsWebMetaSocial",
  })
  const content: ICMSMetaSocial = get(props, `data.${contentKey}`)
  return (
    <SiteWrapper>
      <SiteLayout>
        <PageLocation pageName={ROUTES.account.name}>
          <PageMetaTags data={props} />
          <AccountScreen
            applyToCoachUrl={getAccountScreenApplyToCoachUrl(content)}
          />
        </PageLocation>
      </SiteLayout>
    </SiteWrapper>
  )
}

export default Account

export const query = graphql`
  query AccountScreenQuery(
    $devEnv: Boolean = false
    $stgEnv: Boolean = false
    $uatEnv: Boolean = false
    $prdEnv: Boolean = false
  ) {
    datoCmsWebMetaSocialDev @include(if: $devEnv) {
      ...WebMetaSocialDev
      screenMetaTags: accountScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialStg @include(if: $stgEnv) {
      ...WebMetaSocialStg
      screenMetaTags: accountScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocial @include(if: $uatEnv) {
      ...WebMetaSocial
      screenMetaTags: accountScreenMetaTags {
        ...PageMeta
      }
    }
    datoCmsWebMetaSocialPrd @include(if: $prdEnv) {
      ...WebMetaSocialPrd
      screenMetaTags: accountScreenMetaTags {
        ...PageMeta
      }
    }
  }
`
