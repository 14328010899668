import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import {
  bgImageCss,
  placeholderBackgroundCss,
} from "../../../../../../ui/shared"
import theme from "../../../../../../ui/theme"
import { firebaseApiHandler } from "../../../../../../api/payment/payment"
import { ROUTES } from "../../../../../../navigation/routes"
import { mixpanelEventCancelSubscription } from "../../../../../../analytics/mixpanel"
import { gaEventSubscriptionsCancelSubscription } from "../../../../../../analytics/google"
import CancelSubscriptionPrompt from "../../../../../../components/CancelSubscriptionPrompt/CancelSubscriptionPrompt"
import ResubscribeSubscriptionPrompt from "../../../../../../components/ResubscribeSubscriptionPrompt/ResubscribeSubscriptionPrompt"
import SubscriptionAction from "./components/SubscriptionAction"
import { useOnCoachClick } from "../../../../../../navigation/hooks"

const loadingCss = css`
  h4,
  p {
    color: transparent;
    pointer-events: none;
    ${placeholderBackgroundCss};
  }
`

const StyledContainer = styled.div<{
  loading?: boolean
}>`
  display: flex;
  ${props => (props.loading ? loadingCss : "")};
`

const StyledThumb = styled.div<{
  image: string
  placeholder?: boolean
}>`
  ${bgImageCss};
  background-image: url(${props => props.image});
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin-top: 8px;
  ${props => (props.placeholder ? placeholderBackgroundCss : "")};
`

const StyledInfo = styled.div`
  margin-left: 25px;
  flex: 1;

  h4 {
    font-size: ${theme.fontSizes.medium}px;
    font-weight: ${theme.fontWeights.bold};
  }
`

export const formatDateNumber = (timestamp: number): string => {
  try {
    return new Date(timestamp).toDateString()
  } catch (error) {
    return ""
  }
}

export const PlaceholderSubscribedCoach: React.FC = () => (
  <StyledContainer loading>
    <StyledThumb image="" placeholder />
    <StyledInfo>
      <h4>loading...</h4>
      <p>loading...</p>
      <p>loading...</p>
    </StyledInfo>
  </StyledContainer>
)

const SubscribedCoach: React.FC<{
  isExpired: boolean
  isCancelled: boolean
  coachId: string
  slug: string
  subscriptionId: string
  image: string
  amount: number
  amountRaw: number
  name: string
  nextDate: string
  canceledAt?: number
  refetchSubscriptions: () => Promise<any>
  store: string
}> = ({
  isExpired,
  isCancelled,
  slug,
  subscriptionId,
  image,
  amount,
  amountRaw,
  name,
  nextDate,
  refetchSubscriptions,
  coachId,
  store,
}) => {
  const [justCancelled, setJustCancelled] = useState(false)
  const cancelledState = isCancelled || justCancelled || isExpired
  const [showRenewPrompt, setShowRenewPrompt] = useState(false)
  const [showCancelPrompt, setShowCancelPrompt] = useState(false)
  const [cancelling, setCancelling] = useState(false)
  const [renewing, setRenewing] = useState(false)
  const mixpanel = useMixpanel()
  const onCoachClick = useOnCoachClick()

  const handleRenewSubscription = () => {
    if (renewing) return
    setRenewing(true)
    firebaseApiHandler
      .resubscribeToCoach(subscriptionId)
      .then(() => {
        return refetchSubscriptions()
      })
      .finally(() => {
        setShowRenewPrompt(false)
        setRenewing(false)
      })
  }

  const handleCancelSubscription = () => {
    if (cancelling) return
    setCancelling(true)
    firebaseApiHandler
      .unsubscribeFromCoach(subscriptionId)
      .then(() => {
        mixpanelEventCancelSubscription(mixpanel, name)
        gaEventSubscriptionsCancelSubscription(name, amountRaw)
        setJustCancelled(true)
        return refetchSubscriptions()
      })
      .finally(() => {
        setShowCancelPrompt(false)
        setCancelling(false)
      })
  }
  return (
    <>
      <StyledContainer>
        <Link
          to={ROUTES.coach.paramPath(slug)}
          onClick={() => onCoachClick(coachId)}
        >
          <StyledThumb image={image} />
        </Link>
        <StyledInfo>
          <Link
            to={ROUTES.coach.paramPath(slug)}
            onClick={() => onCoachClick(coachId)}
          >
            <h4>{name}</h4>
          </Link>
          <SubscriptionAction
            cancelledState={cancelledState}
            isExpired={isExpired}
            nextDate={nextDate}
            amount={amount}
            setShowCancelPrompt={setShowCancelPrompt}
            setShowRenewPrompt={setShowRenewPrompt}
            store={store}
            to={ROUTES.coachSignupBilling.paramPath(slug)}
          />
        </StyledInfo>
      </StyledContainer>
      {showCancelPrompt && (
        <CancelSubscriptionPrompt
          busy={cancelling}
          onClose={() => {
            setShowCancelPrompt(false)
          }}
          onCancel={handleCancelSubscription}
        />
      )}
      {showRenewPrompt && (
        <ResubscribeSubscriptionPrompt
          busy={renewing}
          onClose={() => {
            setShowRenewPrompt(false)
          }}
          onRenew={handleRenewSubscription}
        />
      )}
    </>
  )
}

export default SubscribedCoach
