import React, { useState } from "react"
import { Formik } from "formik"
import { get } from "lodash"
import { Link } from "gatsby"
import {
  StyledDesktopWrapper,
  StyledWrapper,
} from "../../../SignInScreen/SignInScreen"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import { StyledDescription } from "../../../../components/CancelSubscriptionPrompt/CancelSubscriptionPrompt"
import { StyledOptionWrapper } from "../../../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/SelectSignUpOptionView"
import ActionButton, {
  ButtonLoadingIndicator,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import { useResetPasswordFormValidation } from "./validation"
import ValidatedInput from "../../../../ui/inputs/ValidatedInput/ValidatedInput"
import { firebaseResetPassword } from "../../../../firebase/auth"
import { ROUTES } from "../../../../navigation/routes"
import { StyledFirebaseError } from "../../../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/components/FirebaseErrorMessage/FirebaseErrorMessage"

export const handleFirebaseErrors = (
  error: Error,
  setErrors: (arg0: any) => void,
  setStatus: (arg0: any) => void,
  valueMapping?: {
    [key: string]: string
  } = {}
) => {
  const code = get(error, "code", "")
  const passwordKey = valueMapping.password ? valueMapping.password : "password"
  if (code === "auth/weak-password") {
    setErrors({
      [passwordKey]: get(error, "message", ""),
    })
  } else if (code === "auth/wrong-password") {
    setErrors({
      [passwordKey]: get(error, "message", ""),
    })
  } else {
    setStatus({ firebaseError: get(error, "message", "") })
  }
}

const ResetPasswordView: React.FC<{
  code: string
}> = ({ code }) => {
  const [passwordHasBeenSet, setPasswordHasBeenSet] = useState(false)
  const validationSchema = useResetPasswordFormValidation()
  const onSubmit = (
    {
      password,
    }: {
      password: string
    },
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: {
      setErrors: (arg0: any) => void
      setStatus: (arg0: any) => void
      setSubmitting: (arg0: boolean) => void
    }
  ) => {
    return firebaseResetPassword(password, code)
      .then(() => {
        setPasswordHasBeenSet(true)
      })
      .catch(error => {
        console.error(error)
        Sentry.captureMessage("Something went wrong when resetting password")
        Sentry.captureException(error)
        handleFirebaseErrors(error, setErrors, setStatus)
      })
  }
  return (
    <StyledDesktopWrapper>
      <StyledWrapper>
        {passwordHasBeenSet ? (
          <div>
            <StyledDescription>
              Your password has been set.{" "}
              <Link to={ROUTES.signIn.path}>Click here to sign in.</Link>
            </StyledDescription>
          </div>
        ) : (
          <Formik
            initialValues={{
              password: "",
              passwordConfirm: "",
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            isInitialValid={false}
          >
            {({
              values,
              errors,
              status,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <MediumPlusHeading>Set your new password</MediumPlusHeading>
                  <StyledDescription>
                    Your password must be at least 6 characters.
                  </StyledDescription>
                  <StyledOptionWrapper>
                    <ValidatedInput
                      name="password"
                      label="New Password"
                      type="password"
                      valid={touched.password && !errors.password}
                      errorMessage={errors.password || ""}
                      invalid={touched.password && !!errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                    />
                  </StyledOptionWrapper>
                  <StyledOptionWrapper>
                    <ValidatedInput
                      name="passwordConfirm"
                      label="Confirm New Password"
                      type="password"
                      valid={touched.passwordConfirm && !errors.passwordConfirm}
                      errorMessage={errors.passwordConfirm || ""}
                      invalid={
                        touched.passwordConfirm && !!errors.passwordConfirm
                      }
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.passwordConfirm}
                    />
                  </StyledOptionWrapper>
                  <StyledOptionWrapper>
                    <ActionButton
                      disabled={!isValid}
                      type="submit"
                      loading={isSubmitting}
                    >
                      Submit
                      {isSubmitting && <ButtonLoadingIndicator />}
                    </ActionButton>
                  </StyledOptionWrapper>
                  {status && status.firebaseError && (
                    <StyledFirebaseError>
                      {status.firebaseError}
                    </StyledFirebaseError>
                  )}
                </form>
              )
            }}
          </Formik>
        )}
      </StyledWrapper>
    </StyledDesktopWrapper>
  )
}

export default ResetPasswordView
