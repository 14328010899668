import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Link } from "gatsby"
import styled from "styled-components"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import {
  BackIcon,
  StyledBackButton,
  StyledContainer,
  StyledHeader,
} from "../AccountSettingsView/AccountSettingsView"
import { ROUTES } from "../../../../navigation/routes"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import ShortlistedCoach, {
  PlaceholderShortlistedCoach,
} from "./components/ShortlistedCoach/ShortlistedCoach"
import theme from "../../../../ui/theme"
import { useUserShortlistedCoaches } from "../../../../auth/components/AuthHandler/hooks"
import { COACH_ENTRY_POINTS } from "../../../CoachScreen/CoachScreen"
import PageLocation from "../../../../analytics/components/PageLocation/PageLocation"
import { getFirestoreCoachSubscriptionPrice } from "../../../../firebase/coach"

const StyledList = styled.ul`
  > li {
    &:not(:first-child) {
      border-top: 1px solid ${theme.colors.faintWhite};
      padding-top: 20px;
      margin-top: 40px;
    }
  }
`

const AccountShortlistView: React.FC<RouteComponentProps> = () => {
  const [coaches, loaded, loading] = useUserShortlistedCoaches()
  return (
    <PageLocation
      coachEntryLocation={COACH_ENTRY_POINTS.Shortlist}
      pageName={ROUTES.accountShortlist.name}
    >
      <StyledContainer>
        <MaxWidthContent>
          <StyledHeader>
            <StyledBackButton as={Link} to={ROUTES.account.path}>
              <BackIcon />
            </StyledBackButton>
            <MediumPlusHeading>Shortlisted Coaches</MediumPlusHeading>
          </StyledHeader>
          <StyledList>
            {!loaded && loading && (
              <>
                <li>
                  <PlaceholderShortlistedCoach />
                </li>
                <li>
                  <PlaceholderShortlistedCoach />
                </li>
                <li>
                  <PlaceholderShortlistedCoach />
                </li>
              </>
            )}
            {!loading && coaches.length === 0 && (
              <li>Your shortlist is currently empty.</li>
            )}
            {coaches.map(coach => (
              <li key={coach.coachId}>
                <ShortlistedCoach
                  coachId={coach.coachId}
                  subscriptionPrice={getFirestoreCoachSubscriptionPrice(coach)}
                  slug={coach.slug}
                  name={coach.name}
                  image={coach.photoUrl}
                />
              </li>
            ))}
          </StyledList>
        </MaxWidthContent>
      </StyledContainer>
    </PageLocation>
  )
}

export default AccountShortlistView
