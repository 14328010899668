import * as Yup from "yup"

export const useSettingsUsernameFormValidation = () => {
  return Yup.object({
    username: Yup.string()
      .matches(/^[a-z0-9_]{3,15}$/g, {
        message:
          "Username must be 15 characters or less and contain only lowercase letters, numbers or underscores.",
      })
      .max(15)
      .required("Username is required"),
  })
}
