import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import theme from "../../../../../../ui/theme"
import {
  bgImageCss,
  imageDropShadowCss,
  placeholderBackgroundCss,
} from "../../../../../../ui/shared"
import { StyledActionButton } from "../../../AccountSubscriptionsView/components/SubscribedCoach/components/SubscriptionAction"
import { ROUTES } from "../../../../../../navigation/routes"
import { removeCoachFromShortlist } from "../../../../../../firebase/user"
import { mixpanelEventRemoveCoach } from "../../../../../../analytics/mixpanel"
import { useIsCoachSubscribedTo } from "../../../../../../auth/components/AuthHandler/hooks"
import { gaEventAccountRemoveShortlist } from "../../../../../../analytics/google"
import { useIsUserAuthenticated } from "../../../../../../auth/components/AuthHandler/AuthHandler"
import { useOnCoachClick } from "../../../../../../navigation/hooks"
import PriceText from "../../../../../../ui/components/PriceText/PriceText"

const StyledContainer = styled.div`
  display: flex;
`

const StyledImage = styled.div<{
  image: string
  placeholder?: boolean
}>`
  ${bgImageCss};
  ${imageDropShadowCss};
  background-image: url(${props => props.image});
  width: 128px;
  height: 182px;
  border-radius: ${theme.borderRadius.common}px;
  margin-right: 40px;
  margin-top: 5px;
  ${props => (props.placeholder ? placeholderBackgroundCss : "")};
`

const StyledInfo = styled.div`
  flex: 1;
`

const loadingCss = css`
  ${placeholderBackgroundCss};
  color: transparent;
  pointer-events: none;
`

const StyledName = styled.div<{
  loading?: boolean
}>`
  font-size: ${theme.fontSizes.medium}px;
  line-height: 28px;
  font-weight: ${theme.fontWeights.bold};
  margin-bottom: 8px;
  ${props => (props.loading ? loadingCss : "")};
`

const StyledActionWrapper = styled.div`
  line-height: 18px;
`

const StyledAction = styled.button`
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  font-weight: ${theme.fontWeights.medium};
`

const StyledPrice = styled.div`
  background: ${theme.colors.secondary};
  color: ${theme.colors.white};
  font-size: 14px;
  line-height: 16px;
  font-weight: ${theme.fontWeights.bold};
  margin: 16px 0;
  padding: 4px 10px;
  border-radius: ${theme.borderRadius.small}px;
  display: inline-block;
`

const StyledSubscribeButton = styled(StyledActionButton)`
  display: block;
  width: 100%;
  margin-bottom: 16px;
  text-align: center;
`

export const PlaceholderShortlistedCoach: React.FC = () => (
  <StyledContainer>
    <StyledImage image="" placeholder />
    <StyledInfo>
      <StyledName loading>loading...</StyledName>
    </StyledInfo>
  </StyledContainer>
)

const ShortlistedCoach: React.FC<{
  coachId: string
  subscriptionPrice: string
  slug: string
  name: string
  image: string
}> = ({ name, subscriptionPrice, image, coachId, slug }) => {
  const mixpanel = useMixpanel()
  const authenticated = useIsUserAuthenticated()
  const [isSubscribed] = useIsCoachSubscribedTo(coachId)
  const [isRemoving, setIsRemoving] = useState(false)
  const [removed, setRemoved] = useState(false)
  const onCoachClick = useOnCoachClick()
  const handleRemove = () => {
    if (isRemoving) return
    setIsRemoving(true)
    mixpanelEventRemoveCoach(mixpanel, name, isSubscribed)
    gaEventAccountRemoveShortlist(name)
    removeCoachFromShortlist(coachId)
      .then(() => {
        setRemoved(true)
      })
      .finally(() => {
        setIsRemoving(false)
      })
  }
  if (removed) return null // in case firebase takes awhile to propagate the changes
  return (
    <StyledContainer>
      <StyledImage image={image} />
      <StyledInfo>
        <StyledName>{name}</StyledName>
        <StyledActionWrapper>
          <StyledAction
            as={Link}
            to={ROUTES.coach.paramPath(slug)}
            onClick={() => onCoachClick(coachId)}
          >
            View Profile
          </StyledAction>
        </StyledActionWrapper>
        <StyledPrice>
          <PriceText
            price={Number(subscriptionPrice)}
            prefix="$"
            suffix=" USD / month"
          />
        </StyledPrice>
        {!isSubscribed && (
          <StyledSubscribeButton
            as={Link}
            to={
              authenticated
                ? ROUTES.coachSignupBilling.paramPath(slug)
                : ROUTES.coachSignup.paramPath(slug)
            }
          >
            Subscribe
          </StyledSubscribeButton>
        )}
        <StyledAction onClick={handleRemove}>
          {isRemoving ? "Removing..." : "Remove"}
        </StyledAction>
      </StyledInfo>
    </StyledContainer>
  )
}

export default ShortlistedCoach
