import React, { useState } from "react"
import { Link } from "gatsby"
import { RouteComponentProps } from "@reach/router"
import { Formik } from "formik"
import { useMixpanel } from "gatsby-plugin-mixpanel"
import {
  BackIcon,
  StyledBackButton,
  StyledContainer,
  StyledHeader,
} from "../AccountSettingsView/AccountSettingsView"
import MaxWidthContent from "../../../../ui/components/MaxWidthContent/MaxWidthContent"
import { ROUTES } from "../../../../navigation/routes"
import MediumPlusHeading from "../../../../ui/typography/MediumPlusHeading/MediumPlusHeading"
import { useSettingsPasswordFormValidation } from "../AccountSettingsPasswordView/validation"
import { useSettingsUsernameFormValidation } from "./validation"
import ValidatedInput from "../../../../ui/inputs/ValidatedInput/ValidatedInput"
import ActionButton, {
  ActionButtonTheme,
  ButtonLoadingIndicator,
} from "../../../../ui/buttons/ActionButton/ActionButton"
import { StyledFirebaseError } from "../../../SignUpScreen/views/CreateStep/views/SelectSignUpOptionView/components/FirebaseErrorMessage/FirebaseErrorMessage"
import {
  StyledInputWrapper,
  StyledSubmitWrapper,
} from "../AccountSettingsPasswordView/AccountSettingsPasswordView"
import {
  useFirebaseDbUser,
  useUserUid,
} from "../../../../auth/components/AuthHandler/AuthHandler"
import {
  getFirestoreUserUsername,
  setUserUsername,
} from "../../../../firebase/user"
import { firebaseApiHandler } from "../../../../api/payment/payment"
import { handleFirebaseErrors } from "../../../SignUpScreen/views/CreateStep/views/EmailSignUpView/EmailSignUpView"
import { mixpanelSetProfileDetails } from "../../../../analytics/mixpanel"

const AccountSettingsUsernameView: React.FC<RouteComponentProps> = () => {
  const user = useFirebaseDbUser()
  const mixpanel = useMixpanel()
  const uid = useUserUid()
  const [usernameUpdated, setUsernameUpdated] = useState(false)
  const validationSchema = useSettingsUsernameFormValidation()
  const onSubmit = (
    {
      username,
    }: {
      username: string
    },
    {
      setErrors,
      setStatus,
      setSubmitting,
    }: {
      setErrors: (arg0: any) => void
      setStatus: (arg0: any) => void
      setSubmitting: (arg0: boolean) => void
    }
  ) => {
    setUsernameUpdated(false)
    return firebaseApiHandler
      .checkForDuplicateUsername(username)
      .then(() => {
        return setUserUsername(username)
          .then(() => {
            mixpanelSetProfileDetails(mixpanel, uid, {
              username,
            })
            setUsernameUpdated(true)
          })
          .catch(error => {
            console.error(error)
            Sentry.captureMessage("Something went wrong when updating username")
            Sentry.captureException(error)
            handleFirebaseErrors(error, setErrors, setStatus)
          })
      })
      .catch(error => {
        console.error(error)
        Sentry.captureException(error)
        setErrors({
          username: "Username already being used.",
        })
      })
  }
  return (
    <StyledContainer>
      <MaxWidthContent>
        <StyledHeader>
          <StyledBackButton as={Link} to={ROUTES.accountSettings.path}>
            <BackIcon />
          </StyledBackButton>
          <MediumPlusHeading>Username</MediumPlusHeading>
        </StyledHeader>
        {user ? (
          <Formik
            initialValues={{
              username: getFirestoreUserUsername(user) || "",
            }}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            isInitialValid={false}
          >
            {({
              values,
              errors,
              status,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => {
              return (
                <form noValidate onSubmit={handleSubmit}>
                  <StyledInputWrapper>
                    <ValidatedInput
                      name="username"
                      label="Username"
                      type="text"
                      valid={touched.username && !errors.username}
                      errorMessage={errors.username || ""}
                      invalid={touched.username && !!errors.username}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                    />
                  </StyledInputWrapper>
                  <StyledSubmitWrapper>
                    <ActionButton
                      theme={ActionButtonTheme.SECONDARY}
                      disabled={!isValid}
                      type="submit"
                      loading={isSubmitting}
                    >
                      Update
                      {isSubmitting && <ButtonLoadingIndicator />}
                    </ActionButton>
                  </StyledSubmitWrapper>
                  {status && status.firebaseError && (
                    <StyledFirebaseError>
                      {status.firebaseError}
                    </StyledFirebaseError>
                  )}
                  {usernameUpdated && (
                    <div>Your username has been updated.</div>
                  )}
                </form>
              )
            }}
          </Formik>
        ) : (
          <div>loading...</div>
        )}
      </MaxWidthContent>
    </StyledContainer>
  )
}

export default AccountSettingsUsernameView
