import * as Yup from "yup"

export const useSettingsPasswordFormValidation = () => {
  return Yup.object({
    password: Yup.string()
      // .min(6)
      .required("Current password is required"),
    newPassword: Yup.string()
      .min(8)
      // .min(2)
      .required("New password is required"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Password confirm is required"),
  })
}
