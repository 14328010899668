import * as Yup from "yup"

export const useResetPasswordFormValidation = () => {
  return Yup.object({
    password: Yup.string()
      .min(8)
      // .min(2)
      .required("Password is required"),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirm is required"),
  })
}
