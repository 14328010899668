import { useEffect, useState } from "react"
import { firebaseApiHandler, IFirestoreSubscription } from "./payment"
import { useIsUserFirebaseAuthenticated } from "../../auth/components/AuthHandler/AuthHandler"
import { useSubscribedCoaches } from "../../auth/components/AuthHandler/hooks"

export const useGetUserSubscriptions = (): [
  IFirestoreSubscription[],
  boolean,
  any,
  () => Promise<any>
] => {
  const authenticated = useIsUserFirebaseAuthenticated()
  const [loading, setLoading] = useState(true)
  const [errorResponse, setError] = useState<any>()
  const [data, setData] = useState<IFirestoreSubscription[]>([])

  const fetchSubscriptions = (): Promise<any> => {
    if (!authenticated) return Promise.reject()
    return firebaseApiHandler
      .getUserSubscriptions()
      .then(response => {
        // console.log("response", response)
        setData(response.data)
      })
      .catch(error => {
        console.warn("failed to get user Subscriptions")
        console.error(error)
        Sentry.captureMessage(
          "Something went wrong when fetching user's subscriptions"
        )
        Sentry.captureException(error)
        setError(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchSubscriptions()
  }, [authenticated])

  const subscribedCoaches = useSubscribedCoaches()
  useEffect(() => {
    if (!loading && authenticated) {
      fetchSubscriptions()
    }
  }, [subscribedCoaches.join(","), authenticated, loading])

  return [data, loading, errorResponse, fetchSubscriptions]
}
